.btnSubmit {
  width: 200px;
}

.toolbar {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.paymentForm {
  width: 98%;
  margin: 0 auto;
}

.creditCardWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.creditCardWrapper img {
  object-fit: cover; /* This ensures the images cover the allotted space without distortion */
}

.checkboxContainer {
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.checkbox {
  width: 1rem;
  height: 1rem;
  margin: 0;
}

.checkboxLabel {
  margin: 0.2rem 0.5rem 0;
}

.paymentWidgetContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  min-height: 100vh;
  background: url('../../../../assets/images/login-background.png') center
    center/cover;

  .header {
    position: relative;
  }

  .headerText {
    font-size: 24px;
    font-weight: bold;
  }

  .headerDate {
    position: absolute;
    right: 10px;
    top: 7px;
  }

  .formGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .formGroup div {
    width: 100%;
  }

  .formGroup div:first-child {
    padding-right: 10px;
  }

  input {
    margin-bottom: 10px;
  }

  .footer {
    display: flex;
    flex-direction: column;

    .footerBtn,
    .copyright {
      color: #fff;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
    }

    .copyright {
      font-size: 10px;
    }
  }

  .amount::-webkit-outer-spin-button,
  .amount::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .amount {
    -webkit-appearance: textfield; /* For WebKit browsers */
    -moz-appearance: textfield; /* For Mozilla Firefox */
    appearance: textfield; /* Standard property for future compatibility */
  }
}

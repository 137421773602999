@import 'assets/styles/_variables.scss', 'sharedStyles.scss';

.form {
  p,
  label > span {
    font-family: 'Montserrat-Medium', sans-serif;
  }

  p,
  input,
  label,
  select,
  select option {
    font-size: 14px;
    margin-bottom: 0;
  }

  small {
    margin-top: 0;
  }
}

.basicDetailsGrid {
  display: grid;
  grid-template-columns: $basicDetailsGridColumnLayout;
  column-gap: $basicDetailsGridColumnGap;
  align-items: center;
}

.paymentOptionsGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: $expandedDetailsPaymentOptionsGridAreas;
  column-gap: 2rem;

  button {
    height: fit-content;
    width: fit-content;
  }
}

.paxDeviceGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: $expandedDetailsPaxDeviceGridAreas;
  column-gap: 2rem;

  button {
    height: fit-content;
    width: fit-content;
  }
}

.buttonContainer,
.buttonContainerCol {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonContainerCol {
  flex-direction: row;
  align-items: flex-end;

  button {
    width: 15rem;
    margin: 0 0.5rem;
  }
}

.hr {
  border-color: $vitusPrimaryOrange;
}

/*
    We could potentially override the boostrap style .is-invalid
    but that may effect other components. This way is scoped
*/
.isInvalid {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
